<script>
import Nav from "@/components/Nav/index.vue";
export default {
    name: "Intro",
    components: {
        Nav,
    },
};
</script>
<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped></style>
